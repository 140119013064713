import {
  addWorkflowToCollection,
  createCollection,
  deleteCollection, deleteWorkflowFromCollection,
  getCollections, getCollectionWorkflows, updateCollection
} from "@/services/api/collection";

const state = {
  collections: [],
  collectionsStatus: '',

  collectionWorkflows: [],
  collectionWorkflowsStatus: ''
}
const mutations = {
  collections_request(state) {
    state.collectionsStatus = 'loading'
  },
  collections_success(state, collections) {
    state.collections = collections
    state.collectionsStatus = 'success'
  },
  collections_create_success(state, collection) {
    state.collections.push(collection)
    state.collectionsStatus = 'success'
  },
  collections_update_success(state, updatedCollection) {
    Object.assign(state.collections.find(collection => collection.id === updatedCollection.id), updatedCollection)
    state.collectionsStatus = 'success'
  },
  collections_delete_success(state, collectionId) {
    state.collections = [
      ...state.collections.filter(collection => collection.id !== collectionId)
    ]
    state.collectionsStatus = 'success'
  },

  collection_workflows_request(state) {
    state.collectionWorkflowsStatus = 'loading'
  },
  collection_workflows_success(state, workflows) {
    state.collectionWorkflows = workflows
    state.collectionWorkflowsStatus = 'success'
  },
  delete_workflow_from_collection_list(state, workflowId) {
    state.collectionWorkflows = [
      ...state.collectionWorkflows.filter(workflow => workflow.id !== workflowId)
    ]
    state.collectionWorkflowsStatus = 'success'
  }
}
const actions = {
  fetchCollections({commit, getters}) {
    commit("collections_request")
    return getCollections({
      'filter[license]': getters.selectedLicense.id
    }).then(collections => {
      commit("collections_success", collections)
    }).catch(error => {
      this.commit('showNotification', {content: error.message, color: 'error'})
    })
  },
  createCollection({commit}, {collection}) {
    commit("collections_request")
    return createCollection(collection)
      .then(collection => {
        commit('collections_create_success', collection)
      }).catch(error => {
        this.commit('showNotification', {content: error.message, color: 'error'})
      })
  },
  updateCollection({commit}, {collection}) {
    commit("collections_request")
    return updateCollection(collection.id, collection)
      .then(collection => {
        commit('collections_update_success', collection)
      }).catch(error => {
        this.commit('showNotification', {content: error.message, color: 'error'})
      })
  },
  deleteCollection({commit}, {collection}) {
    commit("collections_request")
    return deleteCollection(collection.id)
      .then(response => {
        commit('collections_delete_success', collection.id)
      })
      .catch(error => {
        this.commit('showNotification', {content: error.message, color: 'error'})
      })
  },

  fetchWorkflowsInCollection({commit}, collectionId) {
    commit('collection_workflows_request')
    return getCollectionWorkflows(collectionId).then(workflows => {
      commit('collection_workflows_success', workflows)
    }).catch(error => {
      this.commit('showNotification', {content: error.message, color: 'error'})
    })
  },
  deleteWorkflowFromCollection({commit}, {collectionId, workflow}) {
    commit('collection_workflows_request')
    return deleteWorkflowFromCollection(collectionId, workflow.id)
      .then(response => {
        commit('delete_workflow_from_collection_list', workflow.id)
    }).catch(error => {
      this.commit('showNotification', {content: error.message, color: 'error'})
    })
  },
}
const getters = {
  collections: state => state.collections,
  collectionsStatus: state => state.collectionsStatus,

  collectionWorkflows: state => state.collectionWorkflows,
  collectionWorkflowsStatus: state => state.collectionWorkflowsStatus
}

export default {
  state,
  mutations,
  actions,
  getters
}
