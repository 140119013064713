import httpClient from '../httpClient';

const getLicenses = () => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/licenses`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteUserFromLicense = (licenseId, userId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/license/${licenseId}/user/${userId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export { getLicenses, deleteUserFromLicense };
