<template>
  <div>
    <div
      ref="table-container"
      class="ant-glass-background radius-0 ant-border-bottom"
    >
      <dynamic-data-table
        table-title="Projects"
        :table-records="filteredProjects"
        :table-headers="projectHeaders"
        :is-loading="projectsStatus === 'loading'"
        :can-edit="true"
        :can-delete="true"
        :clickable-rows="true"
        :table-height="`${tableHeight}px`"
        :can-refresh="true"
        :items-per-page="10"
        :auto-sort-column="'name'"
        @deleteItem="setupDelete"
        @editItem="setupEdit"
        @reloadData="fetchProjects"
        @clickedRow="selectProject"
      >
        <template #item.license="{ value }">
          <td
            v-if="
              authenticatedUser.licenses.find((license) => license.id === value)
            "
          >
            {{
              authenticatedUser.licenses.find((license) => license.id === value)
                .name
            }}
          </td>
          <td v-else>
            {{ value }}
          </td>
        </template>
        <template #table-buttons>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                class="ant-icon ml-2"
                :color="showArchive ? 'primary' : ''"
                dense
                v-bind="attrs"
                @click="showArchive = !showArchive"
                v-on="on"
              >
                mdi-archive
              </v-icon>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
        <template #table-actions>
          <v-dialog
            key="add-edit-project"
            v-model="projectDialog"
            max-width="500px"
            @click:outside="closeProjectDialog"
          >
            <template #activator="{ on, attrs }">
              <v-tooltip bottom>
                <template #activator="{ on: onTooltip }">
                  <v-icon dense class="ant-icon" v-on="{ ...on, ...onTooltip }">
                    mdi-plus
                  </v-icon>
                </template>
                <span>New project</span>
              </v-tooltip>
            </template>
            <v-card>
              <v-card-title class="justify-center text-uppercase headline">
                {{
                  projectItem.id
                    ? `Edit project: ${projectItem.name}`
                    : 'Add Project'
                }}
              </v-card-title>
              <v-divider />
              <v-form ref="form" lazy-validation>
                <v-img
                  :src="getProjectImageBase64()"
                  max-height="200"
                  min-height="200"
                  class="project-image-container"
                  @click="editProjectImage"
                >
                  <p v-if="!projectItem.image">No project image</p>
                </v-img>
                <v-file-input
                  id="projectImageUpload"
                  v-model="projectItem.image"
                  style="opacity: 0; height: 0"
                  accept="image/png, image/jpeg, image/bmp"
                  hide-input
                  hide-details
                  dense
                  filled
                  single-line
                  label="Project image"
                />

                <div class="px-10 pb-5">
                  <div class="d-flex">
                    <ant-input label="Name" class="flex" :is-optional="false">
                      <template #input-field>
                        <v-text-field
                          v-model="projectItem.name"
                          required
                          maxlength="250"
                          counter="250"
                          :rules="[rules.required, rules.maxLength]"
                          placeholder="Name"
                          hide-details
                          dense
                          filled
                          single-line
                          @input="slugifyProjectName"
                        />
                      </template>
                    </ant-input>

                    <ant-input label="Archive" class="ml-2" :is-optional="true">
                      <template #input-field>
                        <v-switch
                          v-model="projectItem.is_archive"
                          hide-details
                          single-line
                          class="mt-0 pt-2"
                          style="height: 40px"
                        />
                      </template>
                    </ant-input>
                  </div>
                  <div class="d-flex">
                    <ant-input label="Number" class="flex" :is-optional="false">
                      <template #input-field>
                        <v-text-field
                          v-model="projectItem.number"
                          required
                          :rules="[rules.required]"
                          placeholder="Number"
                          hide-details
                          dense
                          filled
                          single-line
                        />
                      </template>
                    </ant-input>

                    <ant-input
                      label="slug"
                      class="ml-2 flex"
                      :is-optional="false"
                    >
                      <template #input-field>
                        <v-text-field
                          v-model="projectItem.slug"
                          readonly
                          disabled
                          required
                          :rules="[rules.required]"
                          placeholder="Slug"
                          hide-details
                          dense
                          filled
                          single-line
                        />
                      </template>
                    </ant-input>
                  </div>
                  <ant-input label="Description" :is-optional="false">
                    <template #input-field>
                      <v-text-field
                        v-model="projectItem.description"
                        :type="'textarea'"
                        required
                        maxlength="50"
                        counter="50"
                        :rules="[rules.required, rules.maxLength]"
                        placeholder="Description"
                        hide-details
                        dense
                        filled
                        single-line
                      />
                    </template>
                  </ant-input>
                </div>
              </v-form>
              <v-card-actions class="ant-border-top ant-dialog-actions-bg">
                <v-spacer />
                <v-btn color="error" text small @click="closeProjectDialog">
                  Cancel
                </v-btn>
                <v-btn color="primary" small elevation="0" @click="saveProject">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </dynamic-data-table>
      <delete-dialog
        :title="`Are you sure you want to remove the project: ${projectItem.name}?`"
        :dialog="projectDeleteDialog"
        @deleteAction="deleteProject"
        @closeDialog="closeProjectDialog"
      />
    </div>
    <transition name="fade">
      <div v-if="selectedProject" class="ant-glass-background radius-0">
        <dynamic-data-table
          :table-title="`Project '${selectedProject.name}' Modules`"
          :table-records="licenseModules"
          :table-headers="moduleHeaders"
          :table-height="`${tableHeight}px`"
          :is-loading="modulesLoading"
          :auto-sort-column="'name'"
        >
          <template #item.icon="{ value }">
            <v-icon color="primary">
              {{ value }}
            </v-icon>
          </template>
          <template #item.active="{ value, rowId }">
            <v-switch
              :ref="`${rowId}-switch`"
              :input-value="isModuleActive(rowId)"
              :disabled="modulesLoading"
              @change="toggleProjectModule(rowId)"
            />
          </template>
        </dynamic-data-table>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DynamicDataTable from '@/components/DynamicDataTable';
import project from '@/store/admin/project';
import slugify from '@/plugins/slugify';
import DeleteDialog from '@/components/DeleteDialog';
import FileHandlerService from '@/services/file-handler';
import {
  addModuleToProject,
  getModulesByLicense,
  removeModuleFromProject,
} from '@/services/api/module.api';
import AntInput from '@/components/AntInput.vue';

const name = 'AProjects';

export default {
  name: name,
  components: { AntInput, DeleteDialog, DynamicDataTable },
  data: () => {
    return {
      projectHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Number', value: 'number' },
        { text: 'Description', value: 'description' },
        { text: 'License', value: 'license', hasSlot: true },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],

      moduleHeaders: [
        {
          text: '',
          value: 'icon',
          align: 'left',
          sortable: false,
          hasSlot: true,
        },
        { text: 'Name', value: 'name' },
        // { text: 'Pool', value: 'pool', hasSlot: true },
        { text: 'Active', value: 'active', hasSlot: true },
      ],

      rules: {
        required: (value) => !!value || 'Required.',
        maxLength: (value) =>
          (value && value.length < 250) || 'Max 250 characters',
      },

      projectItem: {},
      projectDialog: false,
      projectDeleteDialog: false,

      selectedProject: undefined,
      licenseModules: [],
      modulesLoading: false,

      poolModels: [],
      tableHeight: undefined,

      showArchive: false,
    };
  },
  computed: {
    ...mapGetters([
      'projects',
      'projectsArchive',
      'projectsStatus',
      'authenticatedUser',
      'selectedLicense',
    ]),
    filteredProjects() {
      if (this.showArchive) {
        return this.projects.filter((p) => p.is_archive);
      }
      return this.projects.filter((p) => !p.is_archive);
    },
  },
  watch: {
    selectedProject: {
      immediate: true,
      handler(value) {
        if (value) {
          this.tableHeight = window.innerHeight / 2 - 59 - 64 - 45 / 2;
        }
      },
    },
  },
  created() {
    this.$store.registerModule(name, project);
  },
  mounted() {
    this.fetchProjects();
    this.tableHeight = window.innerHeight - 59 - 64 - 45;
  },
  destroyed() {
    this.$store.unregisterModule(name);
  },
  methods: {
    isModuleActive(moduleId) {
      return (
        this.selectedProject.modules.find(
          (module) => module.id === moduleId
        ) !== undefined
      );
    },
    slugifyProjectName() {
      if (
        this.projectItem.name !== null &&
        this.projectItem.name !== undefined
      ) {
        this.projectItem.slug = slugify(this.projectItem.name);
      }
    },
    getProjectImageBase64() {
      if (
        this.projectItem.image !== null &&
        this.projectItem.image !== undefined
      ) {
        if (this.projectItem.image instanceof File) {
          return URL.createObjectURL(this.projectItem.image);
        } else {
          return (
            'data:' +
            this.projectItem.image.mimetype +
            ';base64,' +
            this.projectItem.image.file
          );
        }
      } else {
        return '';
      }
    },
    editProjectImage() {
      document.getElementById('projectImageUpload').click();
    },
    fetchProjects() {
      this.$store.dispatch('fetchProjects', { loadImages: true });
    },
    setupEdit(project) {
      this.projectItem = Object.assign({}, project);
      this.projectDialog = true;
    },
    setupDelete(collection) {
      this.projectItem = Object.assign({}, collection);
      this.projectDeleteDialog = true;
    },
    closeProjectDialog() {
      this.projectDialog = false;
      this.projectDeleteDialog = false;
      this.projectItem = Object.assign({}, project);
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    async saveProject() {
      if (this.$refs.form.validate()) {
        let body = {};

        for (let key of Object.keys(this.projectItem)) {
          if (key === 'image') {
            if (
              this.projectItem[key] !== undefined &&
              this.projectItem[key] !== null &&
              this.projectItem[key].id === undefined
            ) {
              body[key] = await FileHandlerService.handleFile(
                this.projectItem[key]
              );
            }
          } else {
            body[key] = this.projectItem[key];
          }
        }
        body.license = this.selectedLicense.id;

        if (this.projectItem.id !== undefined) {
          // update project
          this.$store
            .dispatch('updateProject', {
              projectId: this.projectItem.id,
              project: body,
            })
            .then(() => {
              this.closeProjectDialog();
            });
        } else {
          // create project
          this.$store.dispatch('createProject', { project: body }).then(() => {
            this.closeProjectDialog();
          });
        }
      }
    },
    deleteProject() {
      this.$store
        .dispatch('deleteProject', { project: this.projectItem })
        .then(() => {
          this.closeProjectDialog();
        });
    },

    selectProject(project) {
      this.selectedProject = project;
      this.modulesLoading = true;
      getModulesByLicense(this.selectedProject.license).then(
        (licenseModules) => {
          this.licenseModules = licenseModules.filter(
            (module) => module.is_master === this.selectedProject.is_master
          );
          this.modulesLoading = false;
        }
      );
    },

    async toggleProjectModule(moduleId) {
      let bool = this.isModuleActive(moduleId);

      this.modulesLoading = true;
      try {
        if (bool) {
          await removeModuleFromProject(moduleId, this.selectedProject.id);

          this.selectedProject.modules = [
            ...this.selectedProject.modules.filter(
              (module) => module.id !== moduleId
            ),
          ];
        } else {
          await addModuleToProject(moduleId, this.selectedProject.id);

          let module = this.licenseModules.find(
            (module) => module.id === moduleId
          );
          module.pool = 20;
          this.selectedProject.modules.push(module);
        }
        this.modulesLoading = false;
      } catch (error) {
        this.$refs[`${moduleId}-switch`].value = bool;
        this.$store.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.project-image-container {
  cursor: pointer;
  background: var(--v-primary-base);
  background: linear-gradient(
    45deg,
    var(--v-primary-base) 10%,
    var(--v-primary-lighten2) 70%,
    var(--v-primary-lighten4) 100%
  );

  p {
    color: #ffffff;
    text-align: center;
    line-height: 200px;
  }
}

.modules-container {
  display: flex;
  padding: 10px;

  .module-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 8px;
    align-items: center;

    .module-name {
      margin-top: 10px;
      font-size: 12px;
    }
  }
}
</style>
