import {getCompanies, createCompany, updateCompany, deleteCompany} from '@/services/api/companies.api'

const state = {
    companies: [],
    companiesStatus: '',
}
const mutations = {
    companies_request(state) {
        state.companiesStatus = 'loading'
    },
    companies_success(state, companies) {
        state.companies = companies
        state.companiesStatus = 'success'
    },
    companies_create_success(state, company) {
        state.companies.push(company)
        state.companiesStatus = 'success'
    },
    companies_update_success(state, updatedCompany) {
        Object.assign(state.companies.find(company => company.id === updatedCompany.id), updatedCompany)
        state.companiesStatus = 'success'
    },
    companies_delete_success(state, deletedCompany) {
        state.companies = [
            ...state.companies.filter(company => company.id !== deletedCompany.id)
        ]
        state.companiesStatus = 'success'
    },
}
const actions = {
    fetchCompanies({commit}) {
        commit('companies_request')
        return getCompanies()
            .then(companies => {
                commit('companies_success', companies)
            })
            .catch(error => {
                this.commit('showNotification', {content: error.message, color: 'error'})
            })
    },
    createCompany({commit}, {company}) {
        commit('companies_request')
        return createCompany(company).then(company => {
            commit('companies_create_success', company)
        }).catch(error => {
            this.commit('showNotification', {content: error.message, color: 'error'})
        })
    },

    updateCompany({commit}, {company}) {
        commit('companies_request')
        return updateCompany(company).then(company => {
            commit('companies_update_success', company)
        }).catch(error => {
            this.commit('showNotification', {content: error.message, color: 'error'})
        })
    },

    deleteCompany({commit}, {company}) {
        commit('companies_request')
        return deleteCompany(company).then(res => {
            commit('companies_delete_success', company)
        }).catch(error => {
            this.commit('showNotification', {content: error.message, color: 'error'})
        })
    },
}
const getters = {
    companies: state => state.companies,
    companiesStatus: state => state.companiesStatus
}

export default {
    state,
    mutations,
    actions,
    getters
}
