<template>
  <div>
    <dynamic-data-table
      class="ant-glass-background radius-0"
      :table-title="`License '${selectedLicense.name}' modules`"
      :table-records="modules"
      :table-headers="moduleHeaders"
      :is-loading="$wait.is('modules.*')"
      :can-edit="true"
      :can-delete="false"
      :table-height="`${tableHeight}px`"
      :can-refresh="true"
      :auto-sort-column="'name'"
      @editItem="setModuleEdit"
      @reloadData="fetchLicenseModules"
    >
      <template #item.icon="{ value }">
        <v-icon color="primary">
          {{ value }}
        </v-icon>
      </template>
      <template #item.active="{ value, rowId }">
        <v-switch
          :input-value="isModuleActive(rowId)"
          @change="toggleModuleActive(rowId)"
        />
      </template>
      <template #table-actions>
        <v-dialog
          v-model="licenseModuleDialog"
          max-width="600px"
          @click:outside="closeLicenseModuleDialog"
        >
          <v-card>
            <v-card-title class="justify-center text-uppercase headline">
              {{
                selectedModule.id
                  ? `Edit module: ${selectedModule.name}`
                  : 'Add module'
              }}
            </v-card-title>
            <v-divider />
            <div class="px-10 pb-5">
              <v-form ref="form" lazy-validation>
                <ant-input label="Name" :is-optional="false">
                  <template #input-field>
                    <v-text-field
                      v-model="selectedModule.name"
                      required
                      maxlength="250"
                      counter="250"
                      :rules="[rules.required, rules.maxLength]"
                      placeholder="Name"
                      hide-details
                      dense
                      filled
                      single-line
                    />
                  </template>
                </ant-input>
                <ant-input label="Icon" :is-optional="false">
                  <template #input-field>
                    <div class="d-flex">
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            large
                            class="mr-2"
                            href="https://materialdesignicons.com/"
                            v-bind="attrs"
                            v-on="on"
                            @click="openMDIList"
                          >
                            {{ selectedModule.icon }}
                          </v-icon>
                        </template>
                        <span>Material icons</span>
                      </v-tooltip>
                      <v-text-field
                        v-model="selectedModule.icon"
                        required
                        :rules="[rules.required]"
                        placeholder="icon slug"
                        hide-details
                        dense
                        filled
                        single-line
                      />
                    </div>
                  </template>
                </ant-input>
                <ant-input label="Category" :is-optional="false">
                  <template #input-field>
                    <v-text-field
                      v-model="selectedModule.category"
                      required
                      maxlength="250"
                      counter="250"
                      :rules="[rules.required, rules.maxLength]"
                      placeholder="Category"
                      hide-details
                      dense
                      filled
                      single-line
                    />
                  </template>
                </ant-input>
                <ant-input label="Description" :is-optional="false">
                  <template #input-field>
                    <v-text-field
                      v-model="selectedModule.description"
                      :type="'textarea'"
                      required
                      maxlength="50"
                      counter="50"
                      :rules="[rules.required, rules.maxLength]"
                      placeholder="Description"
                      hide-details
                      dense
                      filled
                      single-line
                    />
                  </template>
                </ant-input>
              </v-form>
            </div>
            <v-card-actions class="ant-border-top ant-dialog-actions-bg">
              <v-spacer />
              <v-btn color="error" text small @click="closeLicenseModuleDialog">
                Cancel
              </v-btn>
              <v-btn color="primary" small elevation="0" @click="saveModule">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </dynamic-data-table>
  </div>
</template>

<script>
import DynamicDataTable from '@/components/DynamicDataTable.vue';
import { mapActions, mapGetters } from 'vuex';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'AdminModules',
  components: { AntInput, DynamicDataTable },

  data: () => {
    return {
      selectedModule: {},
      licenseModuleDialog: false,
      tableHeight: '100%',

      rules: {
        required: (value) => !!value || 'Required.',
        maxLength: (value) =>
          (value && value.length < 250) || 'Max 250 characters',
      },

      moduleHeaders: [
        {
          text: '',
          value: 'icon',
          align: 'left',
          sortable: false,
          hasSlot: true,
        },
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        // { text: 'Pool', value: 'pool', hasSlot: true },
        { text: 'Active', value: 'active', hasSlot: true },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(['selectedLicense', 'modules', 'licenseModules']),
  },
  mounted() {
    this.fetchLicenseModules();
    this.tableHeight = window.innerHeight - 59 - 64 - 45;
  },
  methods: {
    ...mapActions([
      'getModulesInLicense',
      'addModuleToLicense',
      'deleteModuleFromLicense',
      'updateModule',
    ]),
    openMDIList() {
      window.open('https://materialdesignicons.com/', '_blank');
    },
    fetchLicenseModules() {
      if (this.selectedLicense.id) {
        this.getModulesInLicense(this.selectedLicense.id);
      }
    },
    isModuleActive(moduleId) {
      return !!this.licenseModules.find((lm) => lm === moduleId);
    },
    async toggleModuleActive(moduleId) {
      if (this.isModuleActive(moduleId)) {
        await this.deleteModuleFromLicense({
          moduleId,
          licenseId: this.selectedLicense?.id,
        });
      } else {
        await this.addModuleToLicense({
          moduleId,
          licenseId: this.selectedLicense?.id,
        });
      }
      this.fetchLicenseModules();
    },
    setModuleEdit(module) {
      this.selectedModule = Object.assign({}, module);
      this.licenseModuleDialog = true;
    },
    closeLicenseModuleDialog() {
      this.licenseModuleDialog = false;
      this.projectItem = Object.assign({}, {});
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    saveModule() {
      if (this.$refs.form.validate()) {
        let body = {};
        if (this.selectedModule) {
          const { name, icon, description, category } = this.selectedModule;
          body = { name, icon, description, category };
          this.updateModule({ moduleId: this.selectedModule.id, body }).then(
            () => this.closeLicenseModuleDialog()
          );
        }
      }
    },
  },
};
</script>
