import {
  getUsersFromLicenses,
  createUser,
  updateUser,
  addUserToLicense,
} from '@/services/api/users.api';
import { deleteUserFromLicense } from '@/services/api/license.api';
import { waitForVuex } from '@/utils/vuex';
import { inviteUser } from '@/services/api/user.api';

const state = {
  licenseUsers: [],
};
const mutations = {
  license_users_success(state, users) {
    state.licenseUsers = users;
  },
  add_license_users_success(state, user) {
    let x = state.licenseUsers.find((item) => item.id === user.id);
    if (x) {
      Object.assign((x = user));
    } else {
      state.licenseUsers.push(user);
    }
  },
  delete_license_users_success(state, userId) {
    state.licenseUsers = [
      ...state.licenseUsers.filter((user) => user.id !== userId),
    ];
  },
  update_pro_user_success(state, updatedUser) {
    state.licenseUsers.find((user) => user.id === updatedUser.id).is_pro_user =
      updatedUser.is_pro_user;
  },
};
const actions = {
  fetchUsersInLicense: waitForVuex(
    'license.users.get',
    ({ commit }, license) => {
      return getUsersFromLicenses(license)
        .then((users) => {
          commit(
            'license_users_success',
            users.map((u) => {
              const projects = Array.isArray(u.projects)
                ? u.projects
                : Object.values(u.projects);
              projects.sort((a, b) => (a.name > b.name ? 1 : -1));
              const modules = Array.isArray(u.modules)
                ? u.modules
                : Object.values(u.modules);
              modules.sort((a, b) => (a.name > b.name ? 1 : -1));
              return {
                ...u,
                projects,
                modules,
              };
            })
          );
        })
        .catch((error) => {
          commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    }
  ),
  //FIXME: there is no such api

  // deleteUser({ commit }, { userId, body }) {
  //   return deleteUser(userId, body)
  //     .then((response) => {
  //       this.commit('showNotification', {
  //         content: `Successfully deleted user`,
  //         color: 'success',
  //       });
  //     })
  //     .catch((error) => {
  //       this.commit('showNotification', {
  //         content: `Failed to deleted user`,
  //         color: 'error',
  //       });
  //     });
  // },

  updateUser: waitForVuex(
    'user.update',
    async ({ commit }, { userId, body }) => {
      try {
        const updatedUser = await updateUser(userId, body);
        commit('update_pro_user_success', updatedUser);
        commit('showNotification', {
          content: `Successfully updated user ${updatedUser.name}`,
          color: 'success',
        });
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),
  createUser: waitForVuex('user.create', ({ commit, dispatch }, { body }) => {
    return new Promise((resolve, reject) => {
      createUser(body)
        .then((user) => {
          resolve(user);
        })
        .catch((error) => {
          commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    });
  }),
  inviteUser: waitForVuex('user.invite', async ({ commit }, payload) => {
    try {
      await inviteUser(payload);
      commit('showNotification', {
        content: `The invite has been created`,
        color: 'success',
      });
    } catch (error) {
      commit('showNotification', {
        content: error.message,
        color: 'error',
      });
    }
  }),
  addUserToLicense: waitForVuex(
    'license.user.add',
    ({ commit }, { body, licenseId }) => {
      return addUserToLicense(body, licenseId)
        .then((response) => {
          // parse response to same list format
          let user = response;
          user.license = response.licenses.find(
            (license) => license.id === licenseId
          ).id;
          delete user.licenses;

          commit('add_license_users_success', user);
        })
        .catch((error) => {
          commit('showNotification', {
            content: `Failed to add user to license`,
            color: 'error',
          });
        });
    }
  ),
  deleteUserFromLicense: waitForVuex(
    'license.user.delete',
    ({ commit }, { licenseId, user }) => {
      return deleteUserFromLicense(licenseId, user.id)
        .then((response) => {
          commit('delete_license_users_success', user.id);
          commit('showNotification', {
            content: `Successfully deleted ${user.name} from license`,
            color: 'success',
          });
        })
        .catch((error) => {
          commit('showNotification', {
            content: `Failed to deleted ${user.name} from license`,
            color: 'error',
          });
        });
    }
  ),
};
const getters = {
  licenseUsers: (state) => state.licenseUsers,
  licenseUsersStatus: (state) => state.licenseUsersStatus,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
