import httpClient from '../httpClient';

const getSignForRequest = (uri) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/sign/uri`, { uri })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export { getSignForRequest };
