<template>
  <div>
    <dynamic-data-table
      table-title="Users"
      :table-records="licenseUsers"
      :table-headers="tableHeaders"
      :is-loading="$wait.is('license.users.get')"
      :can-delete="true"
      :can-refresh="true"
      :table-height="`${tableHeight}px`"
      class="ant-glass-background radius-0"
      :auto-sort-column="'lastname'"
      @deleteItem="setupDelete"
      @reloadData="fetchUsersInLicense()"
    >
      <template #item.company="{ value }">
        <v-chip v-if="value" outlined small>
          {{ value.name }}
        </v-chip>
      </template>

      <template #item.modules="{ value }">
        <template v-if="value && value.length > 2">
          <v-menu offset-y auto open-on-hover>
            <template #activator="{ on, attrs }">
              <v-chip
                link
                small
                color="grey lighten-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ value.length }}
              </v-chip>
            </template>
            <v-card class="d-flex flex-wrap pa-1" style="max-width: 200px">
              <div v-for="item in value" :key="item.id" class="pa-1">
                <v-chip outlined small>
                  {{ item.name }}
                </v-chip>
              </div>
            </v-card>
          </v-menu>
        </template>
        <template v-else>
          <div v-for="item in value" :key="item.id" class="pa-1">
            <v-chip outlined small>
              {{ item.name }}
            </v-chip>
          </div>
        </template>
      </template>

      <template #item.projects="{ value }">
        <template v-if="value && value.length > 1">
          <v-menu offset-y auto open-on-hover>
            <template #activator="{ on, attrs }">
              <v-chip
                link
                small
                color="grey lighten-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ value.length }}
              </v-chip>
            </template>
            <v-card class="d-flex flex-wrap pa-1" style="max-width: 200px">
              <div v-for="item in value" :key="item.id" class="pa-1">
                <v-chip outlined small>
                  {{ item.name }}
                </v-chip>
              </div>
            </v-card>
          </v-menu>
        </template>
        <template v-else>
          <div class="d-flex flex-wrap">
            <div v-for="item in value" :key="item.id" class="pa-1">
              <v-chip outlined small>
                {{ item.name }}
              </v-chip>
            </div>
          </div>
        </template>
      </template>

      <template #item.last_active_at="{ value }">
        {{ formatDate(value) }}
      </template>

      <template #table-actions>
        <v-btn
          v-if="!exportSign"
          color="primary"
          :dark="!exportSignLoading"
          class="mr-2"
          small
          elevation="0"
          :loading="exportSignLoading"
          :disabled="exportSignLoading"
          @click="signExportCsv"
        >
          <v-icon> mdi-export </v-icon>
          <span class="ml-2">CSV</span>
        </v-btn>
        <v-btn
          v-else
          color="primary"
          dark
          class="mr-2"
          small
          elevation="0"
          :href="exportCsvUrl"
          target="_blank"
          @click="exportSign = null"
        >
          <v-icon> mdi-download </v-icon>
          <span class="ml-2">Download</span>
        </v-btn>

        <v-divider vertical class="mx-2" inset />

        <v-dialog
          key="invite-user"
          v-model="inviteUserDialog"
          max-width="500px"
          @click:outside="closeUserDialog"
        >
          <template #activator="{ on, attrs }">
            <v-tooltip bottom>
              <template #activator="{ on: onTooltip }">
                <v-icon
                  class="ant-icon"
                  v-on="{ ...on, ...onTooltip }"
                  @click="fetchCompanies()"
                >
                  mdi-email
                </v-icon>
              </template>
              <span>Invite User</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title class="justify-center text-uppercase headline text-center" style="word-break: break-word">
              Invite User to {{ selectedLicense.name }}
            </v-card-title>
            <v-divider />
            <div class="px-10 pb-5">
              <v-form ref="inviteUserForm" @submit.prevent>
                <ant-input label="Email" :is-optional="false">
                  <template #input-field>
                    <v-text-field
                      v-model="inviteUserItem.email"
                      :type="'email'"
                      required
                      :rules="[rules.required, rules.email]"
                      placeholder="Email"
                      hide-details
                      dense
                      filled
                      single-line
                    />
                  </template>
                </ant-input>
                <ant-input label="Company" :is-optional="false">
                  <template #input-field>
                    <v-select
                      v-model="inviteUserItem.company"
                      autocomplete="false"
                      item-text="name"
                      item-value="id"
                      placeholder="Company"
                      :items="availableCompanies"
                      :rules="[rules.required]"
                      hide-details
                      dense
                      filled
                      single-line
                    />
                  </template>
                </ant-input>
              </v-form>
            </div>

            <v-card-actions class="ant-border-top ant-dialog-actions-bg">
              <v-spacer />
              <v-btn
                color="error"
                text
                small
                :disabled="$wait.is('user.invite')"
                @click="closeUserDialog"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                small
                elevation="0"
                :loading="$wait.is('user.invite')"
                @click="inviteUser()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-divider vertical class="mx-2" inset />

        <v-dialog
          v-if="$can('access', 'add-user-to-license')"
          key="add-user"
          v-model="addUserDialog"
          max-width="500px"
          @click:outside="closeUserDialog"
        >
          <template #activator="{ on, attrs }">
            <v-tooltip bottom>
              <template #activator="{ on: onTooltip }">
                <v-icon class="ant-icon" v-on="{ ...on, ...onTooltip }">
                  mdi-account-arrow-right-outline
                </v-icon>
              </template>
              <span>Add existing user to license</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title class="justify-center text-uppercase headline text-center" style="word-break: break-word">
              Add user to {{ selectedLicense.name }}
            </v-card-title>
            <v-divider />
            <div class="px-10 pb-5">
              <v-form ref="licenseForm" @submit.prevent>
                <ant-input label="Email" :is-optional="false" style="flex: 2">
                  <template #input-field>
                    <v-text-field
                      v-model="addUserItem.email"
                      :type="'email'"
                      required
                      :rules="[rules.required, rules.email]"
                      placeholder="Email"
                      hide-details
                      dense
                      filled
                      single-line
                    />
                  </template>
                </ant-input>
              </v-form>
            </div>

            <v-card-actions class="ant-border-top ant-dialog-actions-bg">
              <v-spacer />
              <v-btn
                color="error"
                text
                small
                :disabled="$wait.is('license.user.add')"
                @click="closeUserDialog"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                small
                elevation="0"
                :loading="$wait.is('license.user.add')"
                @click="saveUserToLicense"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-divider vertical class="mx-2" inset />

        <v-dialog
          v-if="$can('access', 'add-user-to-license')"
          key="new-user"
          v-model="userDialog"
          max-width="500px"
          @click:outside="closeUserDialog"
        >
          <template #activator="{ on, attrs }">
            <v-tooltip bottom>
              <template #activator="{ on: onTooltip }">
                <v-icon
                  class="ant-icon"
                  v-on="{ ...on, ...onTooltip }"
                  @click="fetchCompanies()"
                >
                  mdi-account-plus
                </v-icon>
              </template>
              <span>Create user</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title class="justify-center text-uppercase headline text-center" style="word-break: break-word">
              {{ userItem.id ? 'Edit' : 'Add' }} User
              {{ userItem.id ? 'in' : 'to' }}
              {{ selectedLicense.name }}
            </v-card-title>
            <v-divider />

            <v-form ref="userForm" @submit.prevent>
              <div class="px-10 pb-5">
                <div class="d-flex">
                  <ant-input label="Firstname" :is-optional="false">
                    <template #input-field>
                      <v-text-field
                        v-model="userItem.firstname"
                        required
                        :rules="[rules.required]"
                        placeholder="First name"
                        dense
                        filled
                        single-line
                      />
                    </template>
                  </ant-input>
                  <ant-input label="Lastname" :is-optional="false" class="ml-2">
                    <template #input-field>
                      <v-text-field
                        v-model="userItem.lastname"
                        required
                        :rules="[rules.required]"
                        placeholder="Lastname"
                        dense
                        filled
                        single-line
                      />
                    </template>
                  </ant-input>
                </div>
                <ant-input label="Email" :is-optional="false" top-margin="mt-0">
                  <template #input-field>
                    <v-text-field
                      v-model="userItem.email"
                      :type="'email'"
                      required
                      :rules="[rules.required, rules.email]"
                      placeholder="Email"
                      dense
                      filled
                      single-line
                    />
                  </template>
                </ant-input>
                <ant-input
                  label="password"
                  :is-optional="false"
                  top-margin="mt-0"
                >
                  <template #input-field>
                    <v-text-field
                      v-model="userItem.password"
                      required
                      minlength="8"
                      :rules="[rules.password]"
                      :type="'password'"
                      placeholder="Password"
                      dense
                      filled
                      single-line
                    />
                  </template>
                </ant-input>
                <ant-input
                  label="Company"
                  :is-optional="false"
                  style="flex: 2"
                  top-margin="mt-0"
                >
                  <template #input-field>
                    <v-select
                      v-model="userItem.company"
                      autocomplete="false"
                      :items="availableCompanies"
                      item-text="name"
                      item-value="id"
                      placeholder="Company"
                      dense
                      filled
                      single-line
                    />
                  </template>
                </ant-input>
              </div>

              <v-card-actions class="ant-border-top ant-dialog-actions-bg">
                <v-spacer />
                <v-btn
                  color="error"
                  text
                  small
                  :disabled="userSaving"
                  @click="closeUserDialog"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  small
                  elevation="0"
                  :loading="userSaving"
                  @click="saveUser()"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>

      <template #item.access="{ value, rowId, item }">
        <v-btn x-small elevation="0" color="primary" @click="loginAsUser(item)">
          Access
        </v-btn>
      </template>
    </dynamic-data-table>

    <delete-dialog
      :dialog="userDeleteDialog"
      :title="`Are you sure you want to delete '${userItem.email}' from license?`"
      :loading="$wait.is('license.user.delete')"
      @deleteAction="deleteUserFromLicense"
      @closeDialog="closeUserDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import users from '@/store/admin/users';
import DynamicDataTable from '@/components/DynamicDataTable';
import DeleteDialog from '@/components/DeleteDialog';
import moment from 'moment';
import LocalStorageService from '@/services/local-storage';
import { fetchUserAccessToken, inviteUser } from '@/services/api/user.api';
import { getSignForRequest } from '@/services/api/sign.api';
import { getCompanies } from '@/services/api/companies.api';
import AntInput from '@/components/AntInput.vue';
import appConfig from '@/appConfig';

const name = 'AUsers';

export default {
  name: name,
  components: { AntInput, DeleteDialog, DynamicDataTable },
  data: () => {
    return {
      userDialog: false,
      addUserDialog: false,
      userDeleteDialog: false,
      inviteUserDialog: false,
      userItem: { firstname: null, lastname: null },
      addUserItem: {},
      inviteUserItem: {
        admin_user: false,
        email: '',
        company: '',
      },
      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => /.+@.+\..+/.test(value) || 'E-mail must be valid',
        password: (value) => {
          const pattern =
            /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?!.*\s).{8,}$/;
          return (
            pattern.test(value) ||
            'Min. 8 characters with at least one capital letter, a number and a special character and no white spaces.'
          );
        },
      },
      tableHeight: '100%',
      availableCompanies: [],
      exportSign: null,
      exportSignLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'licenseUsers',
      'authenticatedUser',
      'selectedLicense',
      'tok',
    ]),

    tableHeaders() {
      let tmp = [
        { text: 'First name', value: 'firstname' },
        { text: 'Last name', value: 'lastname' },
        { text: 'E-mail', value: 'email' },
        { text: 'Company', value: 'company', hasSlot: true },
        { text: 'Last activity', value: 'last_active_at', hasSlot: true },
        { text: 'Modules', value: 'modules', hasSlot: true },
        { text: 'Projects', value: 'projects', hasSlot: true },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ];
      if (this.$can('access', 'admin-shadow-login')) {
        tmp.push({
          text: 'Access',
          value: 'access',
          align: 'right',
          hasSlot: true,
        });
      }
      return tmp;
    },

    exportCsvUrl() {
      return (
        appConfig.VUE_APP_BASE_URL +
        appConfig.VUE_APP_API_URL +
        `/license/${this.selectedLicense.id}/users/export` +
        `?sign=${this.exportSign}`
      );
    },
    exportCsvSignUrl() {
      return (
        appConfig.VUE_APP_API_URL +
        `/license/${this.selectedLicense.id}/users/export`
      ).substring(1);
    },
    userSaving() {
      return this.$wait.is('user.create') || this.$wait.is('license.user.add');
    },
  },
  created() {
    this.$store.registerModule(name, users);
  },
  mounted() {
    this.fetchUsersInLicense();
  },
  destroyed() {
    this.$store.unregisterModule(name);
  },
  methods: {
    signExportCsv() {
      this.exportSignLoading = true;
      getSignForRequest(this.exportCsvSignUrl)
        .then((data) => {
          this.exportSign = data.sign;
          this.exportSignLoading = false;
        })
        .catch(() => {
          this.exportSignLoading = false;
        });
    },
    loginAsUser(user) {
      if (!this.$can('access', 'admin-shadow-login')) {
        this.$store.commit('showNotification', {
          content: `You have no permissions for this action`,
          color: 'error',
        });
        return;
      }
      fetchUserAccessToken(user.id).then((response) => {
        LocalStorageService.setParentToken(LocalStorageService.getToken());
        LocalStorageService.setToken(response.accessToken);
        this.$store.commit('enable_account_access', user);
        this.$router.push('/dashboard');
      });
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    fetchCompanies() {
      getCompanies().then((companies) => {
        this.availableCompanies = companies.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      });
    },
    closeUserDialog() {
      this.userDialog = false;
      this.addUserDialog = false;
      this.userDeleteDialog = false;
      this.inviteUserDialog = false;
      this.userItem = Object.assign({}, {});
      this.addUserItem = Object.assign({}, {});
      this.inviteUserItem = Object.assign({}, {});
      if (this.$refs.userForm) {
        this.$refs.userForm.reset();
      }
      if (this.$refs.licenseForm) {
        this.$refs.licenseForm.reset();
      }
      if (this.$refs.inviteUserForm) {
        this.$refs.inviteUserForm.reset();
      }
    },
    fetchUsersInLicense() {
      this.$store.dispatch('fetchUsersInLicense', this.selectedLicense.id);
      this.tableHeight = window.innerHeight - 59 - 64 - 45;
    },
    saveUser() {
      if (this.$refs.userForm.validate()) {
        // create user
        let userBody = {};

        Object.keys(this.userItem).forEach((key) => {
          userBody[key] = this.userItem[key];
        });

        if (userBody.company !== undefined) {
          userBody.company = {
            id: userBody.company,
          };
        }

        // store user in db
        this.$store.dispatch('createUser', { body: userBody }).then((user) => {
          let body = {
            user_id: user.id,
          };

          this.$store
            .dispatch('addUserToLicense', {
              body: body,
              licenseId: this.selectedLicense.id,
            })
            .then(() => {
              this.closeUserDialog();
            });
        });
      }
    },
    inviteUser() {
      if (this.$refs.inviteUserForm.validate() && this.selectedLicense) {
        const payload = {
          email: this.inviteUserItem?.email,
          license_id: this.selectedLicense?.id,
          company: { id: this.inviteUserItem?.company },
        };
        if (this.inviteUserItem.admin_user) {
          payload.is_admin = this.inviteUserItem?.admin_user;
        }
        this.$store
          .dispatch('inviteUser', payload)
          .then(() => this.closeUserDialog());
      }
    },
    saveUserToLicense() {
      if (this.$refs.licenseForm.validate()) {
        let body = {
          user_email: this.addUserItem.email,
          pro_user: this.addUserItem.pro_user,
        };

        this.$store
          .dispatch('addUserToLicense', {
            body: body,
            licenseId: this.selectedLicense.id,
          })
          .then(this.closeUserDialog);
      }
    },
    setupDelete(user) {
      this.userItem = Object.assign({}, user);
      this.userDeleteDialog = true;
    },
    deleteUserFromLicense() {
      this.$store
        .dispatch('deleteUserFromLicense', {
          licenseId: this.userItem.license,
          user: this.userItem,
        })
        .then(() => {
          this.closeUserDialog();
        });
    },
  },
};
</script>

<style scoped></style>
