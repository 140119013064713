import httpClient from "../httpClient";

const getCollections = (filters) => {
  return new Promise((resolve, reject) => {
    httpClient.get(`/collections`, {
      params: filters
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const createCollection = (body) => {
  return new Promise((resolve, reject) => {
    httpClient.post(`/collection`, body)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const updateCollection = (collectionId, body) => {
  return new Promise((resolve, reject) => {
    httpClient.put(`/collection/${collectionId}`, body)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const deleteCollection = (collectionId) => {
  return new Promise((resolve, reject) => {
    httpClient.delete(`/collection/${collectionId}`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const getCollection = (collectionId) => {
  return new Promise((resolve, reject) => {
    httpClient.get(`/collection/${collectionId}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const getCollectionWorkflows = (collectionId) => {
  return new Promise((resolve, reject) => {
    httpClient.get(`/collection/${collectionId}/workflows`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const addWorkflowToCollection = (collectionId, workflowId) => {
  return new Promise((resolve, reject) => {
    httpClient.post(`/collection/${collectionId}/workflows/${workflowId}`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const deleteWorkflowFromCollection = (collectionId, workflowId) => {
  return new Promise((resolve, reject) => {
    httpClient.delete(`/collection/${collectionId}/workflows/${workflowId}`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}


export {
  getCollections,
  getCollection,
  createCollection,
  updateCollection,
  deleteCollection,

  getCollectionWorkflows,
  addWorkflowToCollection,
  deleteWorkflowFromCollection
}
