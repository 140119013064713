import {
  createProject,
  deleteProject,
  updateProject,
} from '@/services/api/projects.api';

const state = {};
const mutations = {};

const actions = {
  createProject({ commit }, { project }) {
    this.commit('projects_request');
    return createProject(project)
      .then((response) => {
        this.commit('projects_create_success', response);
        this.commit('showNotification', {
          content: `Successfully created project ${response.name}`,
          color: 'success',
        });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: `Failed to create project`,
          color: 'error',
        });
      });
  },
  updateProject({ commit }, { projectId, project }) {
    this.commit('projects_request');
    return updateProject(project)
      .then((response) => {
        this.commit('projects_update_success', response);
        this.commit('showNotification', {
          content: `Successfully updated project ${response.name}`,
          color: 'success',
        });
      })
      .catch((error) => {
        let content = `Failed to update project.`;
        if (error.message) {
          content = `${content} ${error.message}`;
        }
        this.commit('showNotification', { content, color: 'error' });
        return Promise.reject(error);
      })
      .finally(() => {
        this.commit('projects_request_finish');
      });
  },
  deleteProject({ commit }, { project }) {
    this.commit('projects_request');
    return deleteProject(project.id)
      .then((response) => {
        this.commit('projects_delete_success', project);
        this.commit('showNotification', {
          content: `Successfully deleted project`,
          color: 'success',
        });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: `Failed to delete project`,
          color: 'error',
        });
      });
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
