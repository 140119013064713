<template>
  <div>
    <dynamic-data-table
      table-title="Collections"
      :table-records="collections"
      :table-headers="collectionHeaders"
      :is-loading="collectionsStatus === 'loading'"
      :can-edit="true"
      :can-delete="true"
      :clickable-rows="true"
      :can-refresh="true"
      :table-height="`${tableHeight}px`"
      class="ant-glass-background radius-0 ant-border-bottom"
      :auto-sort-column="'name'"
      @deleteItem="setupDelete"
      @editItem="setupEdit"
      @reloadData="fetchCollections"
      @clickedRow="selectCollection"
    >
      <template #item.created_by="{ value, rowId, item }">
        {{ value.name }}
      </template>
      <template #item.created_at="{ value, rowId, item }">
        {{ parseTimeZone(value) }}
      </template>
      <template #table-actions>
        <v-dialog
          key="add-edit-collection"
          v-model="collectionDialog"
          max-width="500px"
          @click:outside="closeCollectionDialog"
        >
          <template #activator="{ on, attrs }">
            <v-tooltip bottom>
              <template #activator="{ on: onTooltip }">
                <v-icon dense class="ant-icon" v-on="{ ...on, ...onTooltip }">
                  mdi-plus
                </v-icon>
              </template>
              <span>New collection</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title class="justify-center text-uppercase headline">
              {{ collectionItem.id ? 'Edit' : 'Add' }} Collection
            </v-card-title>
            <v-divider />
            <v-form ref="form" @submit.prevent>
              <div class="px-10 pb-5">
                <ant-input label="Name" :is-optional="false">
                  <template #input-field>
                    <v-text-field
                      v-model="collectionItem.name"
                      :rules="[rules.required, rules.maxLength]"
                      placeholder="Name"
                      counter="250"
                      maxlength="250"
                      hide-details
                      dense
                      filled
                      single-line
                    />
                  </template>
                </ant-input>
                <ant-input label="Description" :is-optional="false">
                  <template #input-field>
                    <v-text-field
                      v-model="collectionItem.description"
                      :rules="[rules.required, rules.maxLength]"
                      label="Description"
                      counter="250"
                      maxlength="250"
                      hide-details
                      dense
                      filled
                      single-line
                    />
                  </template>
                </ant-input>
              </div>
              <v-card-actions class="ant-border-top ant-dialog-actions-bg">
                <v-spacer />
                <v-btn
                  color="error"
                  small
                  text
                  @click.stop="closeCollectionDialog"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  small
                  elevation="0"
                  @click.stop="saveCollection"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>
    </dynamic-data-table>
    <delete-dialog
      :dialog="collectionDeleteDialog"
      :title="`Are you sure you want to remove ${collectionItem.name}?`"
      description="This action removes the ability to add workflows, within this collection, to projects"
      @deleteAction="deleteCollection"
      @closeDialog="closeCollectionDialog"
    />

    <transition name="fade">
      <div v-if="selectedCollection">
        <dynamic-data-table
          :table-title="`Collection '${selectedCollection.name}' workflows`"
          :table-records="collectionWorkflows"
          :table-headers="collectionWorkflowHeaders"
          :is-loading="collectionWorkflowsStatus === 'loading'"
          :can-delete="true"
          :can-refresh="true"
          :table-height="`${tableHeight}px`"
          class="ant-glass-background radius-0"
          :auto-sort-column="'name'"
          @deleteItem="setupCollectionWorkflowDelete"
          @reloadData="fetchWorkflowInCollection"
        >
          <template #item.created_by="{ value, rowId, item }">
            {{ value.name }}
          </template>
          <template #item.created_at="{ value, rowId, item }">
            {{ parseTimeZone(value) }}
          </template>
        </dynamic-data-table>

        <delete-dialog
          :title="`Are you sure you want to remove ${collectionItem.name} from this collection?`"
          :dialog="collectionWorkflowDeleteDialog"
          @deleteAction="deleteCollectionWorkflow"
          @closeDialog="closeCollectionWorkflowDialog"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import collections from '@/store/admin/collections';
import DynamicDataTable from '@/components/DynamicDataTable';
import DeleteDialog from '@/components/DeleteDialog';
import moment from 'moment';
import AntInput from '@/components/AntInput.vue';

const name = 'ACollections';

export default {
  name: name,
  components: { AntInput, DeleteDialog, DynamicDataTable },
  data: () => {
    return {
      collectionHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Creator', value: 'created_by', hasSlot: true },
        { text: 'Created at', value: 'created_at', hasSlot: true },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],
      collectionDialog: false,
      collectionDeleteDialog: false,
      collectionItem: {},
      rules: {
        required: (value) => !!value || 'Required.',
        maxLength: (value) =>
          (value && value.length < 250) || 'Max 250 characters',
      },

      selectedCollection: undefined,
      collectionWorkflowHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Creator', value: 'created_by', hasSlot: true },
        { text: 'Created at', value: 'created_at', hasSlot: true },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],
      collectionWorkflowDeleteDialog: false,
      collectionWorkflowItem: {},
      tableHeight: undefined,
    };
  },
  computed: {
    ...mapGetters([
      'collections',
      'collectionsStatus',
      'authenticatedUser',
      'collectionWorkflows',
      'collectionWorkflowsStatus',
      'selectedLicense',
    ]),
  },
  watch: {
    selectedCollection: {
      immediate: true,
      handler(value) {
        if (value) {
          this.tableHeight = window.innerHeight / 2 - 59 - 64 - 45 / 2;
        }
      },
    },
  },
  created() {
    this.$store.registerModule(name, collections);
  },
  mounted() {
    this.fetchCollections();
    this.tableHeight = window.innerHeight - 59 - 64 - 45;
  },
  destroyed() {
    this.$store.unregisterModule(name);
  },
  methods: {
    parseTimeZone(datetime) {
      return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
    },
    fetchCollections() {
      this.$store.dispatch('fetchCollections');
    },
    setupEdit(collection) {
      this.collectionItem = Object.assign({}, collection);
      this.collectionDialog = true;
    },
    setupDelete(collection) {
      this.collectionItem = Object.assign({}, collection);
      this.collectionDeleteDialog = true;
    },
    closeCollectionDialog() {
      this.collectionDialog = false;
      this.collectionDeleteDialog = false;
      this.collectionItem = Object.assign({}, {});
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    saveCollection() {
      if (this.$refs.form.validate()) {
        if (this.collectionItem.id !== undefined) {
          // update collection
          this.$store
            .dispatch('updateCollection', { collection: this.collectionItem })
            .then(() => {
              this.closeCollectionDialog();
            });
        } else {
          this.collectionItem.license = this.selectedLicense.id;
          // create collection
          this.$store
            .dispatch('createCollection', { collection: this.collectionItem })
            .then(() => {
              this.closeCollectionDialog();
            });
        }
      }
    },
    deleteCollection() {
      this.$store
        .dispatch('deleteCollection', { collection: this.collectionItem })
        .then(() => {
          this.closeCollectionDialog();
        });
    },

    selectCollection(collection) {
      this.selectedCollection = collection;
      this.fetchWorkflowInCollection();
    },

    fetchWorkflowInCollection() {
      this.$store.dispatch(
        'fetchWorkflowsInCollection',
        this.selectedCollection.id
      );
    },
    setupCollectionWorkflowDelete(workflow) {
      this.collectionWorkflowItem = Object.assign({}, workflow);
      this.collectionWorkflowDeleteDialog = true;
    },
    closeCollectionWorkflowDialog() {
      this.collectionWorkflowItem = Object.assign({}, {});
      this.collectionWorkflowDeleteDialog = false;
    },

    deleteCollectionWorkflow() {
      this.$store
        .dispatch('deleteWorkflowFromCollection', {
          collectionId: this.selectedCollection.id,
          workflow: this.collectionWorkflowItem,
        })
        .then(() => {
          this.closeCollectionWorkflowDialog();
        });
    },
  },
};
</script>

<style scoped></style>
